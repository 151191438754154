.social-share-button .ssb-icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  display: inline-block;
  height: 18px;
  width: 18px;
}

.social-share-button .ssb-twitter {
  background-image: url('../images/social-share-button/twitter.svg');
}

.social-share-button .ssb-facebook {
  background-image: url('../images/social-share-button/facebook.svg');
}

.social-share-button .ssb-google_bookmark {
  background-image: url('../images/social-share-button/google_bookmark.svg');
}

.social-share-button .ssb-weibo {
  background-image: url('../images/social-share-button/weibo.svg');
}

.social-share-button .ssb-qq {
  background-image: url('../images/social-share-button/qq.svg');
}

.social-share-button .ssb-delicious {
  background-image: url('../images/social-share-button/delicious.svg');
}

.social-share-button .ssb-linkedin {
  background-image: url('../images/social-share-button/linkedin.svg');
}

.social-share-button .ssb-tumblr {
  background-image: url('../images/social-share-button/tumblr.svg');
}

.social-share-button .ssb-pinterest {
  background-image: url('../images/social-share-button/pinterest.svg');
}

.social-share-button .ssb-douban {
  background-image: url('../images/social-share-button/douban.svg');
}

.social-share-button .ssb-wechat {
  background-image: url('../images/social-share-button/wechat.svg');
}

.social-share-button .ssb-vkontakte {
  background-image: url('../images/social-share-button/vkontakte.svg');
}

.social-share-button .ssb-odnoklassniki {
  background-image: url('../images/social-share-button/odnoklassniki.svg');
}

.social-share-button .ssb-xing {
  background-image: url('../images/social-share-button/xing.svg');
}

.social-share-button .ssb-email {
  background-image: url('../images/social-share-button/email.svg');
}

.social-share-button .ssb-reddit {
  background-image: url('../images/social-share-button/reddit.svg');
}

.social-share-button .ssb-hacker_news {
  background-image: url('../images/social-share-button/hacker_news.svg');
}

.social-share-button .ssb-telegram {
  background-image: url('../images/social-share-button/telegram.svg');
}

.social-share-button .ssb-whatsapp_app,
.social-share-button .ssb-whatsapp_web {
  background-image: url('../images/social-share-button/whatsapp.svg');
}

.ss-wechat-dialog {
  width: 300px;
  position: fixed;
  background: #fff;
  padding: 15px;
  border-radius: 3px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
  z-index: 11001;
  font-size: 14px;
  line-height: 1.2em;
}

.ss-wechat-dialog .wechat-popup-header {
  font-weight: bold;
}

.ss-wechat-dialog .wechat-dialog-qr {
  text-align: center;
  margin: 25px 0;
}

.ss-wechat-dialog .wechat-popup-close {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #999;
  text-decoration: none;
  font-size: 16px;
}

.ss-wechat-dialog .wechat-popup-footer {
  text-align: left;
  line-height: 22px;
  color: #666;
}
